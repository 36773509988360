<template>
    <div>
        <div class="notification is-warning" v-if="loading == true">
            Loading client groups ...
        </div>
        

        <div v-if="loading == false">
            <div class="is-pulled-right mb-2" >
                <input type="text" class="input" v-model="search" placeholder="Search ..."/>
            </div>
        </div>

        <div class="is-pulled-left" style="clear:left">
                <h4 class="is-title is-size-4">Active Client Groups</h4>

            </div>
        <div class="notification is-danger" v-if="loading == false && active_client_groups.length == 0" style="clear:left">
            No active client groups can be found matching your search criteria
        </div>
        <table class="table is-fullwidth is-striped is-hoverable" v-if="loading == false && active_client_groups.length > 0">
            <thead>
                <tr>
                    <th width="20">&nbsp;</th>
                    <th>Name</th>
                    <th>Sage Code</th>
                    <th>Type</th>
                    <th>Contact Name</th>
                    <th>Email</th>
                    <th>Clients</th>
                    <th width="50">&nbsp;</th>
                </tr>
            </thead>
            <tbody>
                <tr v-for="row in active_client_groups" :key="row.id">
                    <td style="">
                        <span class="button is-small is-success" v-if="row.risk_level == 1">&nbsp;</span>
                        <span class="button is-small is-warning" v-if="row.risk_level == 2">&nbsp;</span>
                        <span class="button is-small is-danger" v-if="row.risk_level == 3">&nbsp;</span>
                    </td>
                    <td>{{ row.name }}</td>
                    <td>{{ row.sage_code }}</td>
                    <td>{{ row.client_type_name }}</td>
                    <td>{{ row.contact_name }}</td>
                    <td>{{ row.email }}</td>
                    <td>{{  row.clients.length }}</td>
                    <td><router-link :to="'/billing/client-groups/' + row.id"><button class="button is-info is-small">View</button></router-link></td>
                </tr>
               
            </tbody>

        </table>

        <div class="is-pulled-left" >
                <h4 class="is-title is-size-4">Archived Client Groups</h4>

            </div>

        <div class="notification is-danger" v-if="loading == false && archived_client_groups.length == 0">
            No archived client groups can be found matching your search criteria
        </div>
        <table class="table is-fullwidth is-striped is-hoverable" v-if="loading == false && archived_client_groups.length > 0">
            <thead>
                <tr>
                    <th width="20">&nbsp;</th>
                    <th>Name</th>
                    <th>Sage Code</th>
                    <th>Contact Name</th>
                    <th>Email</th>
                    <th>Clients</th>
                    <th width="50">&nbsp;</th>
                </tr>
            </thead>
            <tbody>
                <tr v-for="row in archived_client_groups" :key="row.id">
                    <td style="">
                        <span class="button is-small is-success" v-if="row.risk_level == 1">&nbsp;</span>
                        <span class="button is-small is-warning" v-if="row.risk_level == 2">&nbsp;</span>
                        <span class="button is-small is-danger" v-if="row.risk_level == 3">&nbsp;</span>
                    </td>
                    <td>{{ row.name }}</td>
                    <td>{{ row.sage_code }}</td>
                    <td>{{ row.contact_name }}</td>
                    <td>{{ row.email }}</td>
                    <td>{{  row.clients.length }}</td>
                    <td><router-link :to="'/billing/client-groups/' + row.id"><button class="button is-info is-small">View</button></router-link></td>
                </tr>
               
            </tbody>

        </table>

        
    </div>
</template>

<script>
import UtilityService from '@/services/UtilityService.js';
import MemberMixin from '@/mixins/MemberMixin.js';

export default {
    
      name: 'ClientGroups',
        mixins: [MemberMixin],
        data: function () {
            return {
                   search: '',
                   client_groups: [],
                   loading: false,
                   accessToken: '',
                   user: []
                  
            }
        },
        methods: {
            async getClientGroups() {
                this.loading=true
                UtilityService.getSimpleApiData(this.accessToken, "client_groups")
                .then(
                    (client_groups => {
                        this.$set(this, "client_groups", client_groups);
                        this.loading=false
                    }).bind(this)
                );
            },
            
        },
        created() {
            this.getAccessToken()
            .then(() => { 
                this.getClientGroups();
                this.getUserSelf()
                
            });
        
        },
        computed : {
            active_client_groups: function () {
            
                let active_client_groups =  this.client_groups.filter(function(u) {
                    if(  u.active == 1 && (u.risk_level == 1 || u.risk_level == 2)) 
                    {
                    return true
                    }
                    else
                    {
                    return false
                    }
                })

                if(this.search != '' && this.search != null)
                {
                    active_client_groups = active_client_groups.filter(
                        row => (row.name != null && row.name.toLowerCase().includes(this.search.toLowerCase() ) ) 
                        || (row.email != null && row.email.toLowerCase().includes(this.search.toLowerCase() )) 
                        || (row.sage_code != null && row.sage_code.toLowerCase().includes(this.search.toLowerCase() ) )
                    )
                }

                return active_client_groups
            
            },
            archived_client_groups: function () {
            
                let archived_client_groups =  this.client_groups.filter(function(u) {
                    if(  u.active == 1 && (u.risk_level == 3)) 
                    {
                    return true
                    }
                    else
                    {
                    return false
                    }
                })

                if(this.search != '' && this.search != null)
                {
                    archived_client_groups = archived_client_groups.filter(
                        row => (row.name != null && row.name.toLowerCase().includes(this.search.toLowerCase() ) ) 
                        || (row.email != null && row.email.toLowerCase().includes(this.search.toLowerCase() )) 
                        || (row.sage_code != null && row.sage_code.toLowerCase().includes(this.search.toLowerCase() ) )
                    )
                }

                return archived_client_groups
            
            },
            
        },
        watch: {
            search: function() {
                
                localStorage.client_groups_search = this.search
            },
           
        },
        mounted() {
            if (localStorage.client_groups_search) {
                this.search = localStorage.client_groups_search;
            }
           
            
        },
    }
</script>