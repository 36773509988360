<template>
    <div>
        <div class="is-pulled-right " >
            <div class="columns">
                <div class="column">
                    <input class="input" type="date" v-model="start_date" :disabled="loading==true" @change="getBookings()"/>
                    
                </div>
                <div class="column">
                    <input class="input" type="date" v-model="end_date" :disabled="loading==true" @change="getBookings()"/>
                    
                </div>
                <div class="column">
                    <input class="input" v-model="search" :disabled="loading==true" placeholder="search bookings ..."/>
                </div>
            </div>
            
            
        </div>

        <div class="notification is-warning" v-if="loading == true" style="clear:both">
            Loading bookings ...
        </div>
        <div class="notification is-danger" v-if="loading == false  && filtered_bookings.length == 0" style="clear:both">
            No bookings can be found matching your search criteria
        </div>

        <table class="table is-fullwidth is-striped is-hoverable is-size-7" v-if="loading == false && filtered_bookings.length > 0" style="clear:both" id="bookingstable">
            <thead>
                <tr>
                        <th width="20">ID</th>
                    <th>Client</th>
                    <th>Ward</th>
                    <th>Reference</th>
                    <th>Grade</th>
                    <th>Member</th>
                    <th>Date</th>
                    <th>Time</th>
                    <th>Break</th>
                </tr>
            </thead>
            <tbody>
                <tr v-for="row in filtered_bookings" :key="row.id">
                    
                    <td><a :href="'/bookings/' + row.id" target="_blank">{{ row.id }}</a></td>
                    <td>{{ row.client_name }} <span class="tag is-warning" v-if="row.sleeping_nurse==1">Sleeping Nurse</span></td>
                    <td>{{ row.ward_name }}</td>
                    <td>{{ row.reference }}</td>
                    <td>{{ row.grade_code }}</td>
                    <td><a :href="'/members/' + row.member_id" target="_blank">{{ row.forename }} {{ row.surname  }}</a></td>
                    <td>{{ row.start_date }}</td>
                    <td>{{ row.start_time }} - {{ row.end_time }}</td>
                    <td>{{ row.actual_break_time}}</td>
                </tr>
               
            </tbody>

        </table>

        <p>Found {{ filtered_bookings.length }} bookings</p>

        <button @click="exportXLS()" class="button is-link">Export For Excel</button>
        
    </div>
</template>

<script>
import * as XLSX from 'xlsx'

import UtilityService from '@/services/UtilityService.js';
//import BookingService from '@/services/BookingService.js';
import MemberMixin from '@/mixins/MemberMixin.js';



export default {
    
      name: 'TrustBookings',
        mixins: [MemberMixin],
        data: function () {
            return {
                   search: '',
                   bookings: [],
                   loading: false,
                   working:false,
                   showModal: false,
                   assigned:false,
                   alertText: '',
                   members: [],
                   booking_id: '',
                   showCancelModal: false,
                   end_date: '2023-06-30',
                   start_date : '2023-04-01'
            }
        },
        components: {
            
        },
        methods: {
            exportXLS() {
		
                var wb = XLSX.utils.table_to_book(document.getElementById('bookingstable'), {sheet:"Sheet JS"});
                XLSX.writeFile(wb, 'Processed_Trust_Bookings.xlsx')

            },
            async getBookings() {
                this.loading=true
                UtilityService.getSimpleApiData(this.accessToken, "bookings/trust?start_date=" + this.start_date + "&end_date=" + this.end_date)
                .then(
                    (bookings => {
                        this.$set(this, "bookings", bookings);
                        this.loading=false;
                        this.working = false;
                    }).bind(this)
                );
            },
            getEndDate() {
            
                var d = new Date();
                
                d.setDate(d.getDate() - 5);
                return d.toISOString().slice(0,10);
                
            },
            
           
            
        },
        computed : {
            filtered_bookings: function () {
            
                let filtered_bookings =  this.bookings

                if(this.search != '' && this.search != null)
                {
                    filtered_bookings = filtered_bookings.filter(
                        row => (row.forename != null && row.forename.toLowerCase().includes(this.search.toLowerCase() ) ) 
                        || (row.surname != null && row.surname.toLowerCase().includes(this.search.toLowerCase() )) 
                        || (row.reference != null && row.reference.toLowerCase().includes(this.search.toLowerCase() )) 
                        || (row.client_name != null && row.client_name.toLowerCase().includes(this.search.toLowerCase() ) )
                        || (row.ward_name != null && row.ward_name.toLowerCase().includes(this.search.toLowerCase() ) )
                        || (row.id.toString() != null && row.id.toString().toLowerCase().includes(this.search.toLowerCase() ) )
                    )
                }
                return filtered_bookings
            },
        },
        created() {
            this.getAccessToken()
            .then(() => { 
                this.getBookings();
                
            });
        
        },
    }
</script>