<template>
    <div>
        <div class="notification is-warning" v-if="loading == true">
            Loading timesheets ...
        </div>
        <div class="notification is-danger" v-if="loading == false && bookings.length == 0">
            No timesheets can be found, which is fine.
        </div>

        <table class="table is-fullwidth is-striped is-hoverable" v-if="loading == false && bookings.length > 0">
            <thead>
                <tr>
                    <th width="10">ID</th>
                    <th>Member</th>
                    <th>Client/Ward</th>
                    <th>Grade</th>
                    
                    <th>Date</th>
                    <th>Time</th>
                    <th>Saved Date</th>
                    <th width="60"></th>
                </tr>
            </thead>
            <tbody>
                <tr v-for="row in bookings" :key="row.id">
                    <td>{{ row.id }}</td>
                    <td>{{ row.forename }} {{ row.surname  }}</td>
                    <td>{{ row.client_name }} / {{ row.ward_name }}</td>
                    
                    <td>{{ row.grade_code }}</td>
                    
                    <td>{{ row.start_date }}</td>
                    <td>{{ row.start_time }} - {{ row.end_time }}</td>
                    <td>{{ row.timesheet_saved_date }}</td>
                    <td><button class="button is-small is-link" @click="submitTimesheet(row)" :disabled="working===true">Auto Submit</button></td>
                </tr>
               
            </tbody>

        </table>

        
    </div>
</template>

<script>
import UtilityService from '@/services/UtilityService.js';
import BookingService from '@/services/BookingService.js';
import MemberMixin from '@/mixins/MemberMixin.js';

export default {
    
      name: 'SavedTimesheets',
        mixins: [MemberMixin],
        data: function () {
            return {
                   search: '',
                   bookings: [],
                   loading: true,
                   alertText: '',
                   accessToken: '',
                   working: false
                   
            }
        },
        methods: {
            async getSavedTimesheets() {
                this.loading=true
                UtilityService.getSimpleApiData(this.accessToken, "bookings/completed?timesheet_saved=1&timesheet_submitted=0")
                .then(
                    (bookings => {
                        this.$set(this, "bookings", bookings);
                        this.loading=false
                    }).bind(this)
                );
            },
            async submitTimesheet(booking) {
                var formdata = { 
                    booking_id: booking.id
                }; 

                this.working = true

                BookingService.submitBookingTimesheet(this.accessToken, booking.id, formdata)
                .then(
                    () => {
                        this.working=false;
                        this.getSavedTimesheets();
                    }
                )
                .catch((error) => { 
                    if (error.response.status == 409) {
                        alert(error.response.data.error)
                    } 
                    else{
                        alert(error.message)
                    } 
                    this.working=false;
                })
            }
            
        },
        created() {
            this.getAccessToken()
            .then(() => { 
                this.getSavedTimesheets();
                
            });
        
        },
    }
</script>