

<template>
    <div class="modal" :class="{'is-active': showModal==true}">
        <div class="modal-background"></div>
        <div class="modal-card">
            <header class="modal-card-head">
                <p class="modal-card-title">Approve Timesheet - {{ this.modalBooking.id}}</p>
                <button aria-label="close" class="delete" @click="closeModal()"></button>
            </header>
            <section class="modal-card-body">
                <div class="field is-bold" v-if="this.modalBooking.timesheet_manual == 1">
                    <label class="label">Manual Timesheet</label>
                    <a :href="this.modalBooking.timesheet_aws_url" target="_blank">A manual timesheet was uploaded, view it by clicking here.</a><br />
                </div>
                
                <div class="field is-bold" v-if="this.modalBooking.timesheet_notes != '' && this.modalBooking.timesheet_notes != null">
                    <label class="label">Timesheet Notes</label>
                    <div class="control has-text-danger">{{ this.modalBooking.timesheet_notes }}</div>
                </div>

                <div class="columns" v-if="this.modalBooking.timesheet_manual == 0">
                    <div class="column"><img :src="this.modalBooking.member_signature_aws" /></div>
                    <div class="column"><img :src="this.modalBooking.person_in_charge_signature_aws" /></div>
                </div>
                
                <div class="columns">
                    <div class="column">
                        <div class="field">
                            <label class="label">Forename</label>
                            <div class="control"><input type="text" v-model="this.modalBooking.member_forename" placeholder="" readonly="readonly" disabled="disabled" class="input" ></div>
                        </div>
                    </div>
                    <div class="column">
                        <div class="field">
                            <label class="label">Surname</label>
                            <div class="control"><input type="text" v-model="this.modalBooking.member_surname" placeholder="" readonly="readonly" disabled="disabled" class="input " ></div>
                        </div>
                    </div>
                </div>
                <div class="columns" v-if="this.modalBooking.requires_mileage == 1">
                    <div class="column">
                        <div class="field">
                            <label class="label">Submitted Mileage</label>
                            <div class="control"><input type="number" v-model="this.modalBooking.mileage" placeholder="" readonly="readonly" disabled="disabled" class="input" ></div>
                        </div>
                    </div>
                    <div class="column">
                        <div class="field">
                            <label class="label">Approved Mileage</label>
                            <div class="control"><input type="number" v-model="actual_mileage" placeholder="" class="input " ></div>
                        </div>
                    </div>
                </div>
                <div class="columns">
                    <div class="column">
                        <div class="field">
                            <label class="label">Original Start Date</label>
                            <div class="control"><input type="date" v-model="this.modalBooking.start_date" placeholder="" readonly="readonly" disabled="disabled" class="input" ></div>
                        </div>
                    </div>
                    <div class="column">
                        <div class="field">
                            <label class="label">Actual Start Date</label>
                            <div class="control"><input type="date" v-model="actual_start_date" placeholder="" class="input " ></div>
                        </div>
                    </div>
                </div>
                <div class="columns">
                    <div class="column">
                        <div class="field">
                            <label class="label">Original Start Time</label>
                            <div class="control"><input type="time" v-model="this.modalBooking.start_time" placeholder="" readonly="readonly" disabled="disabled" class="input"></div>
                        </div>
                    </div>
                    <div class="column">
                        <div class="field">
                            <label class="label">Actual Start Time</label>
                            <div class="control"><input type="time" v-model="actual_start_time" placeholder="" class="input " ></div>
                        </div>
                    </div>
                </div>
                <div class="columns">
                    <div class="column">
                        <div class="field">
                            <label class="label">Original End Date</label>
                            <div class="control"><input type="date" v-model="this.modalBooking.end_date" placeholder="" readonly="readonly" disabled="disabled" class="input"></div>
                        </div>
                    </div>
                    <div class="column">
                        <div class="field">
                            <label class="label">Actual End Date</label>
                            <div class="control"><input type="date" v-model="actual_end_date " placeholder="" class="input " ></div>
                        </div>
                    </div>
                </div>
                <div class="columns">
                    <div class="column">
                        <div class="field">
                            <label class="label">Original End Time</label>
                            <div class="control"><input type="time"  v-model="this.modalBooking.end_time" placeholder="" readonly="readonly" disabled="disabled" class="input"></div>
                        </div>
                    </div>
                    <div class="column">
                        <div class="field">
                            <label class="label">Actual End Time</label>
                            <div class="control"><input type="time" v-model="actual_end_time" placeholder="" class="input " ></div>
                        </div>
                    </div>
                </div>
                <div class="columns">
                    <div class="column">
                        <div class="field">
                            <label class="label">Original Break</label>
                            <div class="control"><input type="text" v-model="this.modalBooking.break_time" placeholder="" readonly="readonly" disabled="disabled" class="input"></div>
                        </div>
                    </div>
                    <div class="column">
                        <div class="field">
                            <label class="label">Actual Break</label>
                            <div class="control"><input type="text" v-model="actual_break_time" placeholder="" class="input" ></div>
                        </div>
                    </div>
                </div>
                <div class="columns">
                    <div class="column">
                        <div class="field">
                            <label class="label">Time Worked</label>
                            <div class="control columns">
                                <div class="column">
                                    <input type="text" v-model="this.original_hours_worked" placeholder="" readonly="readonly" disabled="disabled" class="input">
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="column">
                        <div class="field">
                            <label class="label">Actual Time Worked</label>
                            <div class="control columns">
                                <div class="column">
                                    <input type="text" v-model="this.actual_hours_worked" placeholder="" readonly="readonly" disabled="disabled" class="input">
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="field" style="display:none">
                    <label class="label">Notes To Add</label>
                    <div class="control"><textarea placeholder="" class="textarea"></textarea></div>
                </div>
            </section>
            <footer class="modal-card-foot"><button class="button is-success" @click="approveTimesheet()" :disabled="this.working==true">Save</button><button class="button" @click="closeModal()" :disabled="working==true">Cancel</button></footer>
        </div>
    </div>
</template>

<script>
import UtilityService from '@/services/UtilityService.js';
import BookingService from '@/services/BookingService.js';
import MemberMixin from '@/mixins/MemberMixin.js';


export default {
    
      name: 'TimesheetModal',
        mixins: [MemberMixin],
        
        props: ['booking_id', 'showModal'],
        data: function () {
            return {
                   search: '',
                   modalBooking: [],
                   loading: true,
                   searchPerformed: false,
                   assigned:false,
                   alertText: '',
                   members: [],
                   working:false,
                   loadingMembers: true,
                   showUnavailable: 0,
                   showWorking: 0,
                   accessToken: '',
                   actual_break_time: 0,
                   actual_start_date: '',
                   actual_start_time: '',
                   actual_end_date: '',
                   actual_end_time : '',
                   selected_booking_id: '',
                   actual_mileage:''
            }
        },
        methods: {
            async getBooking() {
                this.loading=true
                
                UtilityService.getSimpleApiData(this.accessToken, "bookings/" + this.booking_id + "?include_signatures=1")
                .then(
                    (booking => {
                        this.$set(this, "modalBooking", booking);

                        //if(booking.timesheet_notes == '' || booking.timesheet_notes == null)
                        //{
                            if(this.modalBooking.actual_start_date == null)
                            {
                                this.actual_start_date = this.modalBooking.start_date;
                                this.actual_start_time = this.modalBooking.start_time;
                                this.actual_end_date = this.modalBooking.end_date;
                                this.actual_end_time = this.modalBooking.end_time;
                                this.actual_break_time = this.modalBooking.break_time;
                                this.actual_mileage = this.modalBooking.mileage;
                            }
                            else{
                                this.actual_start_date = this.modalBooking.actual_start_date;
                                this.actual_start_time = this.modalBooking.actual_start_time;
                                this.actual_end_date = this.modalBooking.actual_end_date;
                                this.actual_end_time = this.modalBooking.actual_end_time;
                                this.actual_break_time = this.modalBooking.actual_break_time;
                                this.actual_mileage = this.modalBooking.actual_mileage;
                            }
                        //}

                        this.loading = false
                    }).bind(this)
                );
            },
            
            async closeModal() {
                this.$emit('closeModal')
            },
            async approveTimesheet() {
                var formdata = { 
                    booking_id: this.modalBooking.id,
                    actual_break_time: this.actual_break_time,
                    actual_start_date: this.actual_start_date,
                    actual_start_time: this.actual_start_time,
                    actual_end_date: this.actual_end_date,
                    actual_end_time : this.actual_end_time,
                    actual_mileage : this.actual_mileage,
                }; 

                this.working = true

                BookingService.approveBookingTimesheet(this.accessToken, this.modalBooking.id, formdata)
                .then(
                    () => {
                        this.working=false;
                        //this.getSubmittedTimesheets() 
                        //this.showModal = false    
                        this.$emit('closeModalAndReload')             
                    }
                )
                .catch((error) => { 
                    if (error.response.status == 409) {
                        alert(error.response.data.error)
                    } 
                    else{
                        alert(error.message)
                    } 
                    this.working=false;
                })
            },
            
        },
        computed: {
            original_hours_worked: function() {

                let startdatetime = new Date(this.modalBooking.start_date + 'T' + this.modalBooking.start_time)
                let enddatetime =new Date(this.modalBooking.end_date + 'T' + this.modalBooking.end_time)

                let shiftMins = Math.abs((( startdatetime - enddatetime)/1000)/60);
                var totalMins = shiftMins - this.modalBooking.break_time;

                let actualHrsWorked = Math.floor( totalMins / 60);
                let actualMinsWorked = totalMins % 60;

                return actualHrsWorked + "hrs " + actualMinsWorked + "mins"
            },
            actual_hours_worked: function() {

                let startdatetime = new Date(this.actual_start_date + 'T' + this.actual_start_time)
                let enddatetime =new Date(this.actual_end_date + 'T' + this.actual_end_time)

                let shiftMins = Math.abs((( startdatetime - enddatetime)/1000)/60);
                var totalMins = shiftMins - this.actual_break_time;

                let actualHrsWorked = Math.floor( totalMins / 60);
                let actualMinsWorked = totalMins % 60;

                return actualHrsWorked + "hrs " + actualMinsWorked + "mins"
            },
            
        },
        created() {
            this.getAccessToken()
            .then(() => { 
                
            });
        
        },
        watch: {
            booking_id() {
                this.getBooking();
                
            },
            showModal() {
                this.getBooking();
                
            }
        },
    }
</script>


