<template>
    <div>
        <div class="mb-4">
            <div class="notification is-warning" v-if="loading_checks == true">Performing some background checks</div>

            <div class="" v-if="loading_checks == false">
                <div class="buttons">
                    <div v-if="payroll_checks.start_after_end.length == 0" class="button is-small is-success">All start dates are before end dates</div>
                    <div v-if="payroll_checks.start_after_end.length > 0" class="button is-small is-danger">
                        Some start dates are after end dates : 
                        <span v-for="row in payroll_checks.start_after_end" :key="row.id">
                            {{ row.id }} ({{  row.forename }} {{ row.surname}})
                        </span>
                    </div>

                    <div v-if="payroll_checks.requires_vat.length == 0" class="button is-small is-success">No bookings require VAT</div>
                    <div v-if="payroll_checks.requires_vat.length > 0" class="button is-small is-warning">
                        Some bookings require VAT, please double check these for the first few weeks : 
                        <span v-for="row in payroll_checks.requires_vat" :key="row.id">
                            {{ row.id }} ({{  row.forename }} {{ row.surname}})
                        </span>
                    </div>

                    <div v-if="payroll_checks.invoice_not_match_grade.length == 0" class="button is-small is-success">All invoices match the grade of the shift</div>
                    <div v-if="payroll_checks.invoice_not_match_grade.length > 0" class="button is-small is-danger">
                        Some invoices do not match the grade of the shift :
                        <span v-for="row in payroll_checks.invoice_not_match_grade" :key="row.id">
                            {{ row.id }} ({{  row.forename }} {{ row.surname}})
                        </span>
                    </div>

                    <div v-if="payroll_checks.zero_rate.length == 0" class="button is-small is-success">There are no invoices with a zero rate</div>
                    <div v-if="payroll_checks.zero_rate.length > 0" class="button is-small is-danger">
                        Some invoices have a zero rate or have no invoices :
                        <span v-for="row in payroll_checks.zero_rate" :key="row.id">
                            {{ row.id }} ({{  row.forename }} {{ row.surname}})
                        </span>
                    </div>

                    <div v-if="payroll_checks.eight_year_issue.length == 0" class="button is-small is-success">All bookings for Band 2 or Band 3 have worked 8 years or more</div>
                    <div v-if="payroll_checks.eight_year_issue.length > 0" class="button is-small is-danger">
                        Some members may not be showing eight years working for Band 2/3 :
                        <span v-for="row in payroll_checks.eight_year_issue" :key="row.id">
                            {{ row.id }} ({{  row.forename }} {{ row.surname}})
                        </span>
                    </div>
                    <div v-if="payroll_checks.sleeping_nurse_times.length == 0" class="button is-small is-success">All sleeping nurse shifts are from 8pm to 8am</div>
                    <div v-if="payroll_checks.sleeping_nurse_times.length > 0" class="button is-small is-danger">
                        Some sleeping nurse shifts are not 8pm to 8am - contact David immediately :
                        <span v-for="row in payroll_checks.sleeping_nurse_times" :key="row.id">
                            {{ row.id }} ({{  row.forename }} {{ row.surname}})
                        </span>
                    </div>


                    

                </div>
            </div>
        </div>
        
        <div class="columns" >
            <div class="column">
                Week : <select class="select input" v-model="week">
                    <option :value="row" v-for="row in max_weeks" :key="row">{{ row }}</option>
                </select>
            </div>
            
            <div class="column">
                Year : <select class="select input" v-model="year">
                    <option :value="row" v-for="row in years" :key="row">{{ row }}</option>
                </select>
            </div>
        </div>

        <div class="notification is-warning" v-if="loading == true">
            Loading bookings ...
        </div>
        <div class="notification " v-if="loading == false && members.length == 0">
            No bookings can be found matching your search criteria
        </div>

        
        <div v-if="loading == false && members.length > 0">
            <div class="columns">
                <div class="column">
                    <a :href="API_NAME + '/billing/payroll-short?week=' + this.week + '&year=' + this.year + '&summary=1'" target="_blank"><button class="button is-warning is-fullwidth">Payroll Short CSV</button></a>
                </div>
                <div class="column">
                    <a :href="API_NAME + '/billing/payroll-long?week=' + this.week + '&year=' + this.year " target="_blank"><button class="button is-warning is-fullwidth">Payroll Long CSV</button></a>
                </div>
                <div class="column">
                    <a :href="API_NAME + '/billing/payroll-sage?week=' + this.week + '&year=' + this.year " target="_blank"><button class="button is-warning is-fullwidth">Sage CSV</button></a>
                </div>
                <div class="column">
                    <a :href="API_NAME + '/billing/payroll-trust-invoice-summary?week=' + this.week + '&year=' + this.year " target="_blank"><button class="button is-warning is-fullwidth">Trust Invoice Summary</button></a>
                </div>
                <div class="column">
                    <a :href="API_NAME + '/billing/payroll-private-invoice-summary?week=' + this.week + '&year=' + this.year " target="_blank"><button class="button is-warning is-fullwidth">Private Invoice Summary</button></a>
                </div>
            </div>
            
            <table class="table is-fullwidth  is-hoverable is-size-7 " >
                <thead>
                    <tr>
                        <th>Carer ID</th>
                        <th>Sage</th>
                        <th>Name</th>
                        <th>Grades</th>
                        <th>Timesheets</th>
                        <th>Hrs Worked</th>
                        <th>Avg Per Shift</th>
                        <th>Total Pay</th>
                        <th>ENI</th>
                        <th>Pension</th>
                        
                    </tr>
                </thead>
                <tbody>
                    <template v-for="row in members">
                        <tr :key="'row-' + row.id" @click="toggleInvoicesFor(row.id)" class="">
                            <td>{{ row.id }}</td>
                            <td>{{ row.sage_number }}</td>
                            <td>{{ row.surname }}, {{ row.forename}}</td>
                            <td>{{ row.grade }}</td>
                            <td>{{ row.bookings.length }}</td>
                            <td>{{ row.hours_worked }}</td>
                            <td>{{ row.average_pay }}</td>
                            <td>{{ row.total_pay }}</td>
                            <td>{{ row.eni_total}}</td>
                            <td>{{ row.pension_total}}</td>
                            
                        </tr>
                        <tr :key="'invoice-row-' + row.id" v-show="showInvoicesFor==row.id" class="has-background-white-bis">
                            <td colspan="10">
                                <table class="table is-fullwidth is-size-7 has-background-white-bis">
                                    <thead>
                                        <tr>
                                            <th>id</th>
                                            <th>Booking</th>
                                            <th style="display:none">Member</th>
                                            <th>Grade</th>
                                            <th>Shift Start</th>
                                            <th>Shift End</th>
                                            <th>Client</th>
                                            <th>Ward</th>
                                            <th>Duration</th>
                                            <th>Break</th>
                                            <th>Pay</th>
                                            <th>Rate ID</th>
                                            <th>Total</th>
                                            <th>Ref.</th>
                                            <th>Ann. Leave</th>
                                           
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <template v-for="booking in row.bookings" >
                                            <tr v-for="invoice in booking.invoices" :key="'invoice' + invoice.id">
                                                <td>{{ row.id}}</td>
                                                <td>{{ booking.id}}</td>
                                                <td style="display:none">{{ row.surname }}, {{ row.forename}}</td>
                                                <td>{{ booking.booking_grade_code}}</td>
                                                <td>{{ booking.actual_start_date_time}}</td>
                                                <td>{{ booking.actual_end_date_time}}</td>
                                                <td>{{ booking.client_name}}</td>
                                                <td>{{ booking.ward_name}}</td>
                                                <td>{{ parseFloat(invoice.duration_mins / 60).toFixed(2) }}</td>
                                                <td>{{ invoice.break_time_mins}}</td>
                                                <td>{{ invoice.pay_rate}}</td>
                                                <td :title="invoice.description">{{ invoice.rate_id}}</td>
                                                <td>{{ invoice.total_pay}}</td>
                                                <td>{{ booking.reference}}</td>
                                                <td>{{ invoice.annual_leave}}</td>
                                                
                                            </tr>
                                        </template>
                                    </tbody>
                                </table>
                            </td>
                        </tr>
                    </template>
                    
                
                </tbody>

            </table>
            <p>Number of members on payroll : {{ members.length }} | Timesheets : {{ total_timesheets }} | Hours Worked : {{ total_hours_worked }} | Total Pay : {{ total_pay }}</p>
        </div>
        <div class="modal" :class="{'is-active': showNotesModal==true}">
            <div class="modal-background"></div>
            <div class="modal-card" >
                <header class="modal-card-head">
                    <p class="modal-card-title">Timesheet Notes</p>
                    <button class="delete" aria-label="close" @click="showNotesModal=false"></button>
                </header>
                <section class="modal-card-body " >
                    {{  this.booking_notes }}
                </section>
                
                <footer class="modal-card-foot" >
                <button class="button" @click="showNotesModal=false" :disabled="working==true">Close</button>
                </footer>
            </div>
            
        </div>
        
        
    </div>
</template>

<script>
import BookingService from '@/services/BookingService.js';
//import UtilityService from '@/services/UtilityService.js';
import MemberMixin from '@/mixins/MemberMixin.js';

export default {
    
      name: 'PayrollReport',
        mixins: [MemberMixin],
        data: function () {
            return {
                   search: '',
                   members: [],
                   loading: true,
                   loading_checks: true,
                   showModal: false,
                   showNotesModal: false,
                   alertText: '',
                   modalBooking : [],
                   working:false,
                   booking_notes: '',
                   year: 2023,
                   week: 1,
                   years : [2021, 2022, 2023, 2024],
                   accessToken: '',
                   showInvoicesFor: 0,
                   API_NAME: process.env.VUE_APP_API_NAME,
                   payroll_checks: []
                   
            }
        },
        methods: {
            toggleInvoicesFor(member_id)
            {
                if(this.showInvoicesFor == member_id)
                {
                    this.showInvoicesFor=0
                }
                else{
                    this.showInvoicesFor=member_id
                }
                
            },
            async getProcessedBookings() {
                if(this.week > 0 && this.year > 0)
                {
                    this.loading = true;
                    

                    BookingService.getProcessedBookings(this.accessToken, this.week, this.year)
                    .then(
                        (members => {
                            this.$set(this, "members", members);
                            this.loading = false
                            this.working = false

                            this.getPayrollChecks()
                        
                        }).bind(this)
                    );
                }
                
                
            },
            async getPayrollChecks() {
                if(this.week > 0 && this.year > 0)
                {
                    this.loading_checks = true;
                    
                    BookingService.getPayrollChecks(this.accessToken, this.week, this.year)
                    .then(
                        (payroll_checks => {
                            this.$set(this, "payroll_checks", payroll_checks);
                            this.loading_checks = false

                            
                            
                        }).bind(this)
                    );
                }
                
                
            },
            
            viewTimesheetModal(booking) {
                
                this.modalBooking = booking
                this.showModal = true
            },
            showNotes(timesheet_notes) {
                this.booking_notes = timesheet_notes

                this.showNotesModal = true
            },
            
            
        },
        created() {
            this.getAccessToken()
            .then(() => { 
                this.getProcessedBookings()
                
            });
        
        },
        computed: {
           total_timesheets: function()  {
                return this.members.reduce((acc, member) => acc + member.bookings.length, 0);
           },
           total_hours_worked: function()  {
                return (this.members.reduce((acc, member) => acc + member.hours_worked, 0)).toFixed(2);
           },
           total_pay: function()  {
                return (this.members.reduce((acc, member) => acc + member.total_pay, 0)).toFixed(2);
           }
        },
        watch: {
            week: function() {
                if(this.year > 0 && this.week > 0 && this.accessToken != "")
                {
                    this.getProcessedBookings();
                }
                localStorage.payroll_report_week = this.week
            },
            year: function() {
                if(this.year > 0 && this.week > 0 && this.accessToken != "")
                {
                    this.getProcessedBookings();
                }
                localStorage.payroll_report_year = this.year
            }
        },
        mounted() {
            if (localStorage.payroll_report_week) {
                this.week = localStorage.payroll_report_week;
            }
            
            if (localStorage.payroll_report_year) {
                this.year = localStorage.payroll_report_year;
           }
            
        },
    }
</script>