<template>
    <div>
        <div class="notification is-warning" v-if="loading == true">
            Loading client groups ...
        </div>
        <div class="notification is-danger" v-if="loading == false && client_group == ''">
            No client groups can be found
        </div>

        <div v-if="loading == false && client_group != ''">
            <h4 class="title is-size-4">Client Group Details</h4>
            <form @submit.prevent="updateClientGroup()">
                <div class="field">
                    <label class="label">Client Group Name </label>
                    <div class="control">
                        <input class="input" type="text" v-model="client_group.name"  >
                        
                    </div>
                </div>
                <div class="field ">
                    <div class="columns">
                        <div class="column">
                            <label class="label">Status / Risk Level</label>
                            <div class="control columns" >
                                <div class="column">
                                    <select v-model="client_group.risk_level" required class="input">
                                        <option value="">Please select ...</option>
                                        <option value="1">All Good (Green)</option>
                                        <option value="2">Risky (Amber)</option>
                                        <option value="3">Archived (Red)</option>
                                    </select>

                                </div>
                               
                            </div>
                        </div>
                        <div class="column">
                            <label class="label">Client Type</label>
                            <div class="control columns" >
                                <div class="column">
                                    <select v-model="client_group.client_type_id" required class="input">
                                        <option value="">Please select ...</option>
                                        <option v-for="row in client_types" :key="row.id" :value="row.id">{{ row.name }}</option>
                                        
                                    </select>

                                </div>
                               
                            </div>
                        </div>
                        
                    </div>
                </div>
                
                <div class="field">
                    <label class="label">Address </label>
                    <div class="control columns" >
                        <div class="column"><input class="input" type="text" v-model="client_group.address1"  ></div>
                        <div class="column"><input class="input" type="text" v-model="client_group.address2"  ></div>
                        <div class="column"><input class="input" type="text" v-model="client_group.address3"  ></div>
                    </div>
                </div>
                <div class="field">
                    <label class="label">City / Postcode </label>
                    <div class="control columns" >
                        <div class="column"><input class="input" type="text" v-model="client_group.city"  ></div>
                        <div class="column"><input class="input" type="text" v-model="client_group.postcode"  ></div>
                        
                    </div>
                </div>
                <div class="field ">
                    <div class="columns">
                        <div class="column">
                            <label class="label">Contact Name</label>
                            <div class="control columns" >
                                <div class="column"><input class="input" type="text" v-model="client_group.contact_name"  ></div>
                               
                            </div>
                        </div>
                        <div class="column">
                            <label class="label">Email</label>
                            <div class="control columns" >
                                <div class="column"><input class="input" type="email" v-model="client_group.email"  ></div>
                               
                            </div>
                        </div>
                        <div class="column">
                            <label class="label">Telephone</label>
                            <div class="control columns" >
                                <div class="column"><input class="input" type="text" v-model="client_group.telephone"  ></div>
                               
                            </div>
                        </div>
                    </div>
                </div>

                <div class="field ">
                    <div class="columns">
                        <div class="column">
                            <label class="label">Company Registration Number</label>
                            <div class="control columns" >
                                <div class="column"><input class="input" type="text" v-model="client_group.company_registration"  ></div>

                               
                            </div>
                        </div>
                        <div class="column">
                            <label class="label">Cost Centre</label>
                            <div class="control columns" >
                                <div class="column"><input class="input" type="text" v-model="client_group.cost_centre"  ></div>
                               
                            </div>
                        </div>
                        <div class="column">
                            <label class="label">Calculate ENI/Pension</label>
                            <div class="control columns" >
                                <div class="column">
                                    <select v-model="client_group.calculate_eni_pension" required class="input">
                                        <option value="">Please select ...</option>
                                        <option value="0">No</option>
                                        <option value="1">Yes</option>
                                        
                                    </select>

                                </div>
                               
                            </div>
                        </div>
                    </div>
                    <small>Be very careful changing any of the fields above as they will have consequences on the Billing side of HW4.</small>
                </div>

                <div class="field is-grouped" v-if="this.user.billing==1"><div class="control"><button type="submit" class="button is-success" >Update Client Group</button></div></div>
            </form>
            
            <hr />
            <h4 class="title is-size-4">Clients</h4>
            <div class="notification is-danger" v-if="client_group.clients.length == 0">
                No clients can be found for this group
            </div>
            <table class="table is-fullwidth is-striped is-hoverable" v-if="client_group.clients.length > 0">
                <thead>
                    <tr>
                        <th>Name</th>
                        <th>Code</th>
                        <th>Contract Number</th>
                        <th width="50">&nbsp;</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="row in client_group.clients" :key="row.id">
                        <td>{{ row.name }}</td>
                        <td>{{ row.code }}</td>
                        <td>{{ row.contract_number }}</td>
                        <td><router-link :to="'/billing/clients/' + row.id"><button class="button is-info is-small">View</button></router-link></td>
                    </tr>
                
                </tbody>

            </table>

            <hr />
            <h4 class="title is-size-4">Billing Profiles</h4>
            <div class="notification is-danger" v-if="client_group.billing_profiles.length == 0">
                No billing profiles can be found for this group
            </div>
            <table class="table is-fullwidth is-striped is-hoverable is-size-7" v-if="client_group.billing_profiles.length > 0">
                <thead>
                    <tr>
                        <th>Label</th>
                        <th>Contact Name</th>
                        <th>Address</th>
                        <th>Email</th>
                        
                        <th>Telephone</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="row in client_group.billing_profiles" :key="row.id">
                        <td>{{ row.label }}</td>
                        <td>{{ row.contact_name }}</td>
                        <td>{{ row.address1 }}, {{ row.address2 }}, {{ row.address3 }}, {{ row.city}}, {{ row.postcode }}</td>
                        <td>{{ row.email }}</td>
                        <td>{{ row.telephone }}</td>
                    </tr>
                
                </tbody>

            </table>
        </div>

        
    </div>
</template>

<script>
import UtilityService from '@/services/UtilityService.js';
import BookingService from '@/services/BookingService.js';
import MemberMixin from '@/mixins/MemberMixin.js';

export default {
    
      name: 'ClientGroups',
        mixins: [MemberMixin],
        data: function () {
            return {
                   search: '',
                   client_group: [],
                   loading: false,
                   accessToken: '',
                   user: [{billing: 0}],
                   client_types: []
                  
            }
        },
        methods: {
            async getClientGroup() {
                this.loading=true
                UtilityService.getSimpleApiData(this.accessToken, "client_groups/" + this.$route.params.id)
                .then(
                    (client_group => {
                        this.$set(this, "client_group", client_group);
                        this.loading=false
                    }).bind(this)
                );
            },
            async getClientTypes() {
                UtilityService.getSimpleApiData(this.accessToken, "client_types")
                .then(
                    (client_types => {
                        this.$set(this, "client_types", client_types);
                    }).bind(this)
                );
            },
            async updateClientGroup() {
                this.working = true;
                
                    var formdata = { 
                        _method: 'patch',
                        risk_level : this.client_group.risk_level,
                        name: this.client_group.name,
                        address1: this.client_group.address1,
                        address2: this.client_group.address2,
                        address3: this.client_group.address3,
                        city: this.client_group.city,
                        postcode: this.client_group.postcode,
                        contact_name: this.client_group.contact_name,
                        telephone: this.client_group.telephone,
                        email: this.client_group.email,
                        client_type_id: this.client_group.client_type_id,
                        cost_centre: this.client_group.cost_centre,
                        calculate_eni_pension: this.client_group.calculate_eni_pension,
                        company_registration: this.client_group.company_registration,
                    }; 

                    BookingService.updateClientGroup(this.accessToken, this.client_group.id, formdata)
                    .then(
                        () => {
                            this.working=false;
                            
                            this.getClientGroup();
                            alert("Client Group Updated")
                        }
                    ).catch((error) => { 
                        console.log(error)
                        if (error.response.status == 409) {
                            alert(error.response.data.error)
                        } 
                        else{
                            alert(error.message)
                        } 
                        this.working=false;
                        
                    });
            }
            
        },
        created() {
            this.getAccessToken()
            .then(() => { 
                this.getClientTypes();
                this.getClientGroup();
                this.getUserSelf()
            });
        
        },
    }
</script>