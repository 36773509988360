//import UtilityService from '@/services/UtilityService.js';

export default {
    methods: {
        
        
    },
    created() {
        
    },
    mounted() {
        
    },
    destroyed() {
      
    },
    computed: {
        filtered_bookings: function () {
            
            let filtered_bookings =  this.bookings

            if(this.search != '' && this.search != null)
            {
                filtered_bookings = filtered_bookings.filter(
                    row => (row.forename != null && row.forename.toLowerCase().includes(this.search.toLowerCase() ) ) 
                    || (row.surname != null && row.surname.toLowerCase().includes(this.search.toLowerCase() )) 
                    || (row.grade_code != null && row.grade_code.toLowerCase().includes(this.search.toLowerCase() )) 
                    || (row.client_name != null && row.client_name.toLowerCase().includes(this.search.toLowerCase() ) )
                    || (row.id.toString() != null && row.id.toString().toLowerCase().includes(this.search.toLowerCase() ) )
                )
            }
            return filtered_bookings
        },
    }
  };