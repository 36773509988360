<template>
    <div>
        <div class="is-pulled-right bulma-clearfix-mixin" >
            <input class="input" v-model="search" placeholder="search bookings ..."/>
        </div>

        <div class="notification is-warning bulma-clearfix-mixin" v-if="loading == true" style="clear:both">
            Loading bookings ...
        </div>
        <div class="notification bulma-clearfix-mixin" v-if="loading == false && filtered_bookings.length == 0" style="clear:both">
            No bookings can be found matching your search criteria
        </div>

        <div v-if="loading == false && filtered_bookings.length > 0" style="clear:both">
            <table class="table is-fullwidth is-striped is-hoverable is-size-7" >
                <thead>
                    <tr>
                        <th>ID</th>
                        <th>Client</th>
                        <th>Ward</th>
                        <th>Reference</th>
                        <th>Grade</th>
                        <th>Member</th>
                        <th>Date</th>
                        <th>Time</th>
                        <th>Submitted</th>
                        <th width="60">&nbsp;</th>
                        <th width="60">&nbsp;</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="row in filtered_bookings" :key="row.id" :class="{'has-background-warning' : row.timesheet_notes != null && row.timesheet_notes != ''}">
                        <td>{{ row.id }}</td>
                        <td>{{ row.client_name }}</td>
                        <td>
                            <span v-if="row.requires_mileage == 1">{{ row.mileage }} miles</span>
                            <span v-else>{{ row.ward_name }}</span>
                        </td>
                        <td>
                            {{ row.reference }}
                            <div class="tag is-danger " v-if="row.reference == ''">No reference</div>
                        </td>
                        <td>{{ row.grade_code }}</td>
                        <td>{{ row.forename }} {{ row.surname  }}</td>
                        <td>{{ row.start_date }}</td>
                        <td>{{ row.start_time }} - {{ row.end_time }}</td>
                        <td>{{ row.timesheet_submitted_date }}</td>
                        <td style="padding:0px"><button class="button is-small is-info" @click="showNotes(row.timesheet_notes)" v-if="row.timesheet_notes != null && row.timesheet_notes != ''">Notes</button></td>
                        <td style="padding:0px"><button class="button is-success is-small" @click="viewTimesheetModal(row)" >View</button></td>
                    </tr>
                
                </tbody>

            </table>
        </div>

        <TimesheetModal :booking_id="selected_booking_id" :showModal="showTimesheetModal" @closeModal="closeTimesheetModal" @closeModalAndReload="reloadBookings" @reloadBookings="reloadBookings"/>
           
        <div class="modal" :class="{'is-active': showNotesModal==true}">
            <div class="modal-background"></div>
            <div class="modal-card" >
                <header class="modal-card-head">
                    <p class="modal-card-title">Timesheet Notes</p>
                    <button class="delete" aria-label="close" @click="showNotesModal=false"></button>
                </header>
                <section class="modal-card-body " >
                    {{  this.booking_notes }}
                </section>
                
                <footer class="modal-card-foot" >
                <button class="button" @click="showNotesModal=false" :disabled="working==true">Close</button>
                </footer>
            </div>
            
        </div>
        
    </div>
</template>

<script>
//import BookingService from '@/services/BookingService.js';
import UtilityService from '@/services/UtilityService.js';
import MemberMixin from '@/mixins/MemberMixin.js';
import BookingMixin from '@/mixins/BookingMixin.js';

import TimesheetModal from '@/components/Billing/TimesheetModal.vue';

export default {
    
      name: 'SavedTimesheets',
        mixins: [MemberMixin, BookingMixin],
        components: {
            TimesheetModal
        },
        data: function () {
            return {
                   search: '',
                   bookings: [],
                   loading: false,
                   showTimesheetModal: false,
                   showNotesModal: false,
                   alertText: '',
                   modalBooking : [],
                   working:false,
                   booking_notes: '',
                    accessToken: '',
                   selected_booking_id: ''
                   
            }
        },
        methods: {
            async getSubmittedTimesheets() {
                this.loading=true
                UtilityService.getSimpleApiData(this.accessToken, "bookings/completed?timesheet_submitted=1&timesheet_approved=0&include_signatures=1")
                .then(
                    (bookings => {
                        this.$set(this, "bookings", bookings);
                        this.loading=false
                        
                    }).bind(this)
                );
            },
            
            viewTimesheetModal(booking) {
                this.modalBooking = booking
                this.selected_booking_id = booking.id // Set this for the modal
                this.showTimesheetModal = true

                //this.timeWorked();
            },
            showNotes(timesheet_notes) {
                this.booking_notes = timesheet_notes

                this.showNotesModal = true
            },
            async reloadBookings() {
                this.showTimesheetModal = false
                this.getSubmittedTimesheets();
            },
           
        },
        created() {
            this.getAccessToken()
            .then(() => { 
                this.getSubmittedTimesheets();
                
            });
        
        },
       
    }
</script>