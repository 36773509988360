<template>
    <div>
        <h2 class="title is-size-4">Un-Sent Trust Invoices (Probably didn't have a reference on payroll day)</h2>

        <div class="notification is-warning" v-if="loading == true">
            Loading timesheets ...
        </div>
        <div class="notification is-danger" v-if="loading == false && bookings.length == 0">
            No timesheets can be found, which is fine.
        </div>

        <table class="table is-fullwidth is-striped is-hoverable is-size-7" v-if="loading == false && bookings.length > 0">
            <thead>
                <tr>
                    <th width="10">ID</th>
                    <th>Member</th>
                    <th>Client/Ward</th>
                    <th>Email</th>
                    <th>Grade</th>
                    <th>Reference</th>
                    <th>Date</th>
                    <th>Time</th>
                    <th>Year</th>
                    <th>Week</th>
                    <th>&nbsp;</th>
                    <th>&nbsp;</th>
                </tr>
            </thead>
            <tbody>
                <tr v-for="row in bookings" :key="row.id">
                    <td><router-link :to="'/bookings/' + row.id" target="_blank">{{ row.id }}</router-link></td>
                    <td>{{ row.forename }} {{ row.surname  }}</td>
                    <td>{{ row.client_name }} / {{ row.ward_name }}</td>
                    <td><a :href="'mailto:' + row.billing_profile.email + '?subject=Reference required for Booking ' + row.id" target="_blank">{{  row.billing_profile.email  }}</a></td>
                    <td>{{ row.grade_code }}</td>
                    <td>{{ row.reference }}</td>
                    <td>{{ row.actual_start_date }}</td>
                    <td>{{ row.actual_start_time }} - {{ row.actual_end_time }}</td>
                    <td>{{ row.year }}</td>
                    <td>{{ row.week }}</td>
                    <td>
                        <button class="button is-small is-success" @click="resendInvoice(row.id)" :disabled="working===true" v-if="row.reference != ''">Send Invoice</button>
                        <button class="button is-small is-danger" v-if="row.reference == ''">No Reference</button>
                    </td>
                    <td><button class="button is-small is-link" @click="markAsSent(row.id)" :disabled="working===true">Mark As Sent</button></td>
                </tr>
               
            </tbody>

        </table>

        
    </div>
</template>

<script>
import UtilityService from '@/services/UtilityService.js';
import BookingService from '@/services/BookingService.js';
import MemberMixin from '@/mixins/MemberMixin.js';

export default {
    
      name: 'MissingReferences',
        mixins: [MemberMixin],
        data: function () {
            return {
                   search: '',
                   bookings: [],
                   loading: true,
                   alertText: '',
                   accessToken: '',
                   working: false
                   
            }
        },
        methods: {
            async getUnSentInvoices() {
                this.loading=true
                UtilityService.getSimpleApiData(this.accessToken, "bookings/completed?timesheet_saved=1&timesheet_processed=1&unsent_invoice=1&order_by=year DESC,week DESC")
                .then(
                    (bookings => {
                        this.$set(this, "bookings", bookings);
                        this.loading=false
                    }).bind(this)
                );
            },
            async resendInvoice(booking_id) {
                this.working=true;

                if(confirm('Are you sure you want to re-send the invoice ?'))
                {
                
                    var formdata = { 
                        //email : this.booking.billing_profile.email
                            empty_field:0 // Just so there is something here.
                    }; 

                    BookingService.sendInvoice(this.accessToken, booking_id, formdata)
                    .then(
                        () => {
                            this.working=false;
                            
                            alert("Invoice Email Re-Sent")

                            this.getUnSentInvoices();
                        }
                    ).catch((error) => { 
                        
                        if (error.response.status == 409) {
                            alert(error.response.data.error)
                        } 
                        else{
                            alert(error.response.data.message)
                        } 
                        this.working=false;
                        
                    });
                    return true;
                }
                else
                {
                    
                    this.working=false;
                    return false;
                }
            },
            async markAsSent(booking_id) {
                this.working = true
                if(confirm('Are you sure you want to mark this invoice as Sent ?'))
                {
                    var formdata = { 
                        _method: 'patch',
                        invoice_sent: 1
                    };
                    BookingService.updateBooking(this.accessToken, booking_id, formdata)
                    .then(
                        () => {
                            this.working=false;
                            
                            this.getUnSentInvoices();

                        }
                    );
                }
                else{
                    this.working=false;
                    return false
                }
            },
            
            
        },
        created() {
            this.getAccessToken()
            .then(() => { 
                this.getUnSentInvoices();
                
            });
        
        },
    }
</script>