<template>
    <div>
        <div class="notification is-warning" v-if="loading == true">
            Loading members ...
        </div>
        <div class="notification is-danger" v-if="loading == false && filtered_members.length == 0">
            No members can be found matching your search criteria
        </div>

        <div class="is-pulled-left buttons">
            <button class="button" @click="toggleArchived()" :class="{'is-success' : this.include_archived == 1}">Include Archived</button>
            <button class="button">{{ filtered_members.length }} members</button>
        </div>
        <div class="is-pulled-right" v-if="loading == false" >
            
            <input type="text" class="input" v-model="search" placeholder="Search ..."/>
        </div>

        <table class="table is-fullwidth is-striped is-hoverable" v-if="loading == false && filtered_members.length > 0">
            <thead>
                <tr>
                    <th>Name</th>
                    <th>Nationality</th>
                    <th>NI Number</th>
                    <th>Sage Number</th>
                    
                    <th width="50">&nbsp;</th>
                </tr>
            </thead>
            <tbody>
                <tr v-for="row in filtered_members" :key="row.id">
                    <td>{{ row.forename }} {{ row.surname }}</td>
                    <td>{{ row.nationality_name }} </td>
                    <td>{{ row.national_ins }} </td>
                    <td><input type="text" class="input" v-model="row.sage_number" /></td>
                    
                    <td><button type="button" class="button is-success is-small" :disabled="working==true" @click="updateSageNumber(row)">Update</button></td>
                </tr>
               
            </tbody>

        </table>

        
    </div>
</template>

<script>
import MemberService from '@/services/MemberService.js';
import MemberMixin from '@/mixins/MemberMixin.js';

export default {
    
      name: 'SageNumbers',
        mixins: [MemberMixin],
        data: function () {
            return {
                member_search: '',
                search: '',
                   members: [],
                   loading: false,
                   accessToken: '',
                   order_by: 'sage_number,surname, forename ASC',
                   include_archived: 0,
                   sage_valid_only: 0,
                   working: false
                  
            }
        },
        methods: {
            async updateSageNumber(row) {

                this.working = true
                
                var formdata = { 
                    _method: 'patch',
                    sage_number: row.sage_number,
                    
                }; 

                MemberService.updateMember(this.accessToken, formdata, row.id)
                .then((response) => {
                    this.getMembers(this.order_by, this.include_archived, this.sage_valid_only);
                    console.log(response)
                    this.working = false
                    
                    }).catch((error) => {
                        if(error.response.status === 422)
                        {
                            console.log("Error:" + JSON.stringify(error.response.data));
                        }
                        alert("Something went wrong. Make sure you have filled in all the fields. \nOr ring David !!")
                        this.working = false
                });
            },
            toggleArchived() {
                if(this.include_archived == 0 || this.include_archived == '')
                {
                    this.include_archived = 1
                    this.sage_valid_only = 1
                } else {
                    this.include_archived = 0
                    this.sage_valid_only = 0
                }
                this.getMembers(this.order_by, this.include_archived, "", this.sage_valid_only);
            }
        },
        created() {
            this.getAccessToken()
            .then(() => { 
                this.getMembers(this.order_by, this.include_archived, this.sage_valid_only);
            });
        
        },
        computed : {
            filtered_members: function () {
            
            let filtered_members =  this.members.filter(function(u) {
                if(  u.active == 1) 
                {
                return true
                }
                else
                {
                return false
                }
            })

            if(this.search != '' && this.search != null)
            {
                filtered_members = filtered_members.filter(
                    row => (row.name != null && row.name.toLowerCase().includes(this.search.toLowerCase() ) ) 
                    || (row.surname != null && row.surname.toLowerCase().includes(this.search.toLowerCase() )) 
                    || (row.sage_number != null && row.sage_number.toLowerCase().includes(this.search.toLowerCase() ) )
                )
            }

            return filtered_members
        
        },
        }
    }
</script>