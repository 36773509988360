<template>
    <div>
        <div class="notification is-warning" v-if="loading == true">
            Loading bookings ...
        </div>
        <div class="notification is-danger" v-if="loading == false && filtered_bookings.length == 0">
            No bookings can be found, which is fine.
        </div>

        <div class="is-pulled-right mb-2" v-if="loading == false">
            <input type="text" class="input" v-model="search" placeholder="Search ..."/>
        </div>

        <table class="table is-fullwidth is-striped is-hoverable is-size-7" v-if="loading == false && filtered_bookings.length > 0">
            <thead>
                <tr>
                    <th width="60"></th>
                    <th >ID</th>
                    <th >Reference</th>
                    <th>Member</th>
                    <th>Client/Ward</th>
                    <th>Grade</th>
                    
                    <th>Date</th>
                    <th>Time</th>
                    
                    
                </tr>
            </thead>
            <tbody>
                <tr v-for="row in filtered_bookings" :key="row.id">
                    <td><button class="button is-small is-success" @click="showUploadModal(row.id)" :disabled="working===true">Upload  PDF</button></td>
                    <td>{{ row.id }}</td>
                    <td>{{ row.reference }}</td>
                    <td>{{ row.forename }} {{ row.surname  }}</td>
                    <td>{{ row.client_name }} / {{ row.ward_name }}</td>
                    
                    <td>{{ row.grade_code }}</td>
                    
                    <td>{{ row.start_date }}</td>
                    <td>{{ row.start_time }} - {{ row.end_time }}</td>
                    
                    
                </tr>
               
            </tbody>

        </table>

        <div class="modal" :class="{'is-active': showModal==true}">
            <div class="modal-background"></div>
            <div class="modal-card">
                <header class="modal-card-head">
                <p class="modal-card-title">Upload Timesheet</p>
                <button class="delete" aria-label="close" @click="showModal=false"></button>
                </header>
                <section class="modal-card-body">
                    <form action="" @submit.prevent="uploadTimesheet()" enctype="multipart/form-data">
                        <div class="field">
                        <label class="label">Select File</label>
                        <div class="control">
                            <input class="input" type="file" placeholder="Choose Timesheet PDF" ref="inputFile" accept=".pdf" v-on:change="handleFileUpload($event)" required >
                        </div>
                        </div>
                        <div class="control">
                        <button class="button is-primary" type="submit" :disabled="working==true">Upload</button>
                        </div>
                    </form>
                </section>
                
                
            </div>
        </div>

        
    </div>
</template>

<script>
import UtilityService from '@/services/UtilityService.js';
import BookingService from '@/services/BookingService.js';
import MemberMixin from '@/mixins/MemberMixin.js';

export default {
    
      name: 'MissingTimesheets',
        mixins: [MemberMixin],
        data: function () {
            return {
                   search: '',
                   bookings: [],
                   loading: true,
                   alertText: '',
                   accessToken: '',
                   working: false,
                   showModal: false,
                   selected_booking_id: 0,
                   file : '',
                  
                   
            }
        },
        methods: {
            async getMissingTimesheets() {
                this.loading=true
                UtilityService.getSimpleApiData(this.accessToken, "bookings/completed?timesheet_saved=0&timesheet_submitted=0&timesheet_processed=0")
                .then(
                    (bookings => {
                        this.$set(this, "bookings", bookings);
                        this.loading=false
                        
                    }).bind(this)
                );
            },
            showUploadModal(booking_id)
            {
                this.selected_booking_id = booking_id
                this.showModal = true
            },
            handleFileUpload(event){
                this.file = event.target.files[0];
                
            },
            async uploadTimesheet() {
                this.working = true
                let formData = new FormData();
                formData.append('file', this.file);

                BookingService.uploadTimesheet(this.accessToken, this.selected_booking_id, formData)
                .then((response) => {
                    this.showModal = false
                    this.$refs.inputFile.value = '';
                    this.file= ''
                    this.getMissingTimesheets();
                    console.log('Response:', response);
                    this.working=false
                    
                    }).catch((error) => {
                        if(error.response.status === 422)
                        {
                            alert("We think you tried to upload something other than a PDF file or you did not choose a file. Please select a PDF file and try again.")
                        }
                        else if(error.response.status === 404)
                        {
                            alert("The selected booking could not be found.\n Please try again or contact David.")
                        }
                        else if(error.response.status === 409)
                        {
                            alert("It looks like the timesheet has already been submitted.")
                        }
                        else{
                            alert("Something went wrong. Make sure you have filled in all the fields. \nOr ring David !!")
                        }
                        console.log("Error:" + JSON.stringify(error.response.data));
                        this.working=false
                        
                });
            }
            
            
        },
        created() {
            this.getAccessToken()
            .then(() => { 
                this.getMissingTimesheets();
                
            });
        
        },
        computed : {
            filtered_bookings: function () {
            
                let filtered_bookings =  this.bookings

                if(this.search != '' && this.search != null)
                {
                    filtered_bookings = filtered_bookings.filter(
                        row => (row.id != null && row.id.toString().toLowerCase().includes(this.search.toLowerCase() ) ) 
                        || (row.forename != null && row.forename.toLowerCase().includes(this.search.toLowerCase() )) 
                        || (row.surname != null && row.surname.toLowerCase().includes(this.search.toLowerCase() ) )
                    )
                }

                return filtered_bookings
            
            },
        }
    }
</script>