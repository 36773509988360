<template>
    <div>
        <div class="notification is-warning" v-if="loading == true">
            Loading bookings ...
        </div>
        <div class="notification is-danger" v-if="loading == false && bookings.length == 0">
            No bookings can be found matching your search criteria
        </div>

        <table class="table is-fullwidth is-striped is-hoverable" v-if="loading == false && bookings.length > 0">
            <thead>
                <tr>
                    <th>Client</th>
                    <th>Ward</th>
                    <th>Grade</th>
                    <th>Member</th>
                    <th>Date</th>
                    <th>Time</th>
                    
                </tr>
            </thead>
            <tbody>
                <tr v-for="row in bookings" :key="row.id">
                    <td>{{ row.client_name }}</td>
                    <td>{{ row.ward_name }}</td>
                    <td>{{ row.grade_code }}</td>
                    <td>{{ row.forename }} {{ row.surname  }}</td>
                    <td>{{ row.start_date }}</td>
                    <td>{{ row.start_time }} - {{ row.end_time }}</td>
                    
                </tr>
               
            </tbody>

        </table>

        
    </div>
</template>

<script>
import UtilityService from '@/services/UtilityService.js';
import MemberMixin from '@/mixins/MemberMixin.js';

export default {
    
      name: 'CompletedBookings',
        mixins: [MemberMixin],
        data: function () {
            return {
                   search: '',
                   bookings: [],
                   loading: false,
                  
                   alertText: '',
                   
            }
        },
        methods: {
            async getCompletedBookings() {
                this.loading=true
                UtilityService.getSimpleApiData(this.accessToken, "bookings/completed?timesheet_saved=0&timesheet_submitted=0")
                .then(
                    (bookings => {
                        this.$set(this, "bookings", bookings);
                        this.loading=false
                    }).bind(this)
                );
            },
            
        },
        created() {
            this.getAccessToken()
            .then(() => { 
                this.getCompletedBookings();
                
            });
        
        },
    }
</script>