<template>
    <div>
        <div class="notification is-warning" v-if="loading == true">
            Loading clients ...
        </div>
        

        <div v-if="loading == false">
            <div class="is-pulled-right mb-2" >
                <input type="text" class="input" v-model="search" placeholder="Search ..."/>
            </div>
        </div>

        <div class="is-pulled-left" style="clear:left">
                <h4 class="is-title is-size-4">Active Clients</h4>

            </div>
        <div class="notification is-danger" v-if="loading == false && active_clients.length == 0" style="clear:left">
            No active clients can be found matching your search criteria
        </div>
        <table class="table is-fullwidth is-striped is-hoverable" v-if="loading == false && active_clients.length > 0">
            <thead>
                <tr>
                    <th>Name</th>
                    <th>Group</th>
                    <th>Contact Name</th>
                    <th>Email</th>
                    
                    <th width="50">&nbsp;</th>
                </tr>
            </thead>
            <tbody>
                <tr v-for="row in active_clients" :key="row.id">
                    
                    <td>{{ row.name }}</td>
                    <td>{{ row.client_group_name }}</td>
                    <td>{{ row.contact_name }}</td>
                    <td>{{ row.email }}</td>
                    
                    <td><router-link :to="'/billing/clients/' + row.id"><button class="button is-info is-small">View</button></router-link></td>
                </tr>
               
            </tbody>

        </table>

        <div class="is-pulled-left" >
                <h4 class="is-title is-size-4">Archived Clients</h4>

            </div>

        <div class="notification is-danger" v-if="loading == false && archived_clients.length == 0">
            No archived clients can be found matching your search criteria
        </div>
        <table class="table is-fullwidth is-striped is-hoverable" v-if="loading == false && archived_clients.length > 0">
            <thead>
                <tr>
                    <th>Name</th>
                    <th>Group</th>
                    <th>Contact Name</th>
                    <th>Email</th>
                    
                    <th width="50">&nbsp;</th>
                </tr>
            </thead>
            <tbody>
                <tr v-for="row in archived_clients" :key="row.id">
                    
                    <td>{{ row.name }}</td>
                    <td>{{ row.client_group_name }}</td>
                    <td>{{ row.contact_name }}</td>
                    <td>{{ row.email }}</td>
                   
                    <td><router-link :to="'/billing/clients/' + row.id"><button class="button is-info is-small">View</button></router-link></td>
                </tr>
               
            </tbody>

        </table>

        
    </div>
</template>

<script>
import UtilityService from '@/services/UtilityService.js';
import MemberMixin from '@/mixins/MemberMixin.js';

export default {
    
      name: 'ClientGroups',
        mixins: [MemberMixin],
        data: function () {
            return {
                   search: '',
                   clients: [],
                   loading: false,
                   accessToken: '',
                   user: []
                  
            }
        },
        methods: {
            async getClients() {
                this.loading=true
                UtilityService.getSimpleApiData(this.accessToken, "clients?exclude_risky=0")
                .then(
                    (clients => {
                        this.$set(this, "clients", clients);
                        this.loading=false
                    }).bind(this)
                );
            },
            
        },
        created() {
            this.getAccessToken()
            .then(() => { 
                this.getClients();
                this.getUserSelf()
                
            });
        
        },
        computed : {
            active_clients: function () {
            
                let active_clients =  this.clients.filter(function(u) {
                    if(  u.active == 1 && (u.risk_level == 1 || u.risk_level == 2)) 
                    {
                    return true
                    }
                    else
                    {
                    return false
                    }
                })

                if(this.search != '' && this.search != null)
                {
                    active_clients = active_clients.filter(
                        row => (row.name != null && row.name.toLowerCase().includes(this.search.toLowerCase() ) ) 
                        || (row.email != null && row.email.toLowerCase().includes(this.search.toLowerCase() )) 
                        
                    )
                }

                return active_clients
            
            },
            archived_clients: function () {
            
                let archived_clients =  this.clients.filter(function(u) {
                    if(  u.active == 1 && (u.risk_level == 3)) 
                    {
                    return true
                    }
                    else
                    {
                    return false
                    }
                })

                if(this.search != '' && this.search != null)
                {
                    archived_clients = archived_clients.filter(
                        row => (row.name != null && row.name.toLowerCase().includes(this.search.toLowerCase() ) ) 
                        || (row.email != null && row.email.toLowerCase().includes(this.search.toLowerCase() )) 
                       
                    )
                }

                return archived_clients
            
            },
            
        },
        watch: {
            search: function() {
                
                localStorage.clients_search = this.search
            },
           
        },
        mounted() {
            if (localStorage.clients_search) {
                this.search = localStorage.clients_search;
            }
           
            
        },
    }
</script>