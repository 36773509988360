<template>
    <div>
        <div class="is-pulled-right bulma-clearfix-mixin" >
            <input class="input" v-model="search" placeholder="search bookings ..."/>
        </div>

        <div class="notification is-warning" v-if="loading == true" style="clear:both">
            Loading bookings ...
        </div>
        <div class="notification " v-if="loading == false && filtered_bookings.length == 0"  style="clear:both">
            No bookings can be found matching your search criteria
        </div>

        <div v-if="loading == false && filtered_bookings.length > 0">
            <h4 class="title is-size-4">Total Timesheets to be Processed : {{  filtered_bookings.length }}</h4>
            
            <table class="table is-fullwidth is-striped is-hoverable is-size-7"   style="clear:both">
                <thead>
                    <tr>
                        <th>ID</th>
                        <th>Client</th>
                        <th>Ward</th>
                        <th>Reference</th>
                        <th>Grade</th>
                        <th>Started </th>
                        <th>Member</th>
                        <th>Date</th>
                        <th>Time</th>
                        <th>Actual</th>
                        <th>Break</th>
                        <th width="60">&nbsp;</th>
                        <th width="60">&nbsp;</th>
                        <th width="10">&nbsp;</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="row in filtered_bookings" :key="row.id" :class="{'has-background-warning' : row.timesheet_notes != null && row.timesheet_notes != ''}">
                        <td>{{ row.id }}</td>
                        <td>{{ row.client_name }}</td>
                        <td>
                            <span v-if="row.requires_mileage == 1">{{ row.actual_mileage }} miles</span>
                            <span v-else>{{ row.ward_name }}</span>
                        </td>
                        <td style="padding:0px" v-if="row.reference == ''">
                            <div class="tag " v-if="row.reference == ''">No reference</div>
                        </td>
                        <td v-if="row.reference != ''">
                            {{ row.reference }}
                        </td>
                        <td>{{ row.grade_code }}
                            <span class="tag is-danger" v-if="checkBookingGrade(row.client_type_id, row.grade_id) == true">Check Grade</span>
                        </td>
                        <td>{{ row.first_worked_in_trust }}</td>
                        <td>{{ row.forename }} {{ row.surname  }}</td>
                        <td>{{ row.start_date }}</td>
                        <td>{{ row.start_time }} - {{ row.end_time }}</td>
                        <td>{{ row.actual_start_time }} - {{ row.actual_end_time }}</td>
                        <td>{{ row.actual_break_time}}</td>
                        <td style="padding:0px"><button class="button is-small is-info" @click="showNotes(row.timesheet_notes)" v-if="row.timesheet_notes != null && row.timesheet_notes != ''">Notes</button></td>
                        <td  style="padding:0px"><button class="button is-success is-small" @click="viewProcessModal(row)" :class="{'is-danger' : row.reference == ''}">Process</button></td>
                        <td ><label class="checkbox"><input type="checkbox" class="" v-model="row.checked"/></label></td>
                    </tr>
                
                </tbody>

            </table>
        </div>

        <div class="modal" :class="{'is-active': showNotesModal==true}">
            <div class="modal-background"></div>
            <div class="modal-card" >
                <header class="modal-card-head">
                    <p class="modal-card-title">Timesheet Notes</p>
                    <button class="delete" aria-label="close" @click="showNotesModal=false"></button>
                </header>
                <section class="modal-card-body " >
                    {{  this.booking_notes }}
                </section>
                
                <footer class="modal-card-foot" >
                <button class="button" @click="showNotesModal=false" :disabled="working==true">Close</button>
                </footer>
            </div>
            
        </div>

        <div v-if="checkedBookings.length > 0">
            <hr />
            <div class="columns">
                <div class="column">
                    <div class="field">
                        <label class="label">Week</label>
                        <div class="control">
                            
                        <select class="input" v-model="week" >
                            <option value="">Select ...</option>
                            <option :value="n" v-for="n in max_weeks" :key="n">{{ n }}</option>
                            
                        </select></div>
                    </div>
                </div>
                <div class="column">
                    <div class="field">
                        <label class="label">Year</label>
                        <div class="control"><select class="input" v-model="year">
                            <option value="2022">2022</option>
                            <option value="2023">2023</option>
                            <option value="2024">2024</option>
                            <option value="2025">2025</option>
                        </select>
                    </div>
                    </div>
                </div>
                <div class="column is-half">
                    <div class="field">
                        &nbsp;
                    </div>
                </div>
            </div>
            <button class="button is-success" @click="processTimesheets()" :disabled="working==true">Process Selected Timesheets</button>
        </div>


        <div class="modal" :class="{'is-active': showModal==true}">
            <div class="modal-background"></div>
            <div class="modal-card">
                <header class="modal-card-head">
                    <p class="modal-card-title">Process Timesheet {{ this.modalBooking.id }}</p>
                    <button aria-label="close" class="delete" @click="closeProcessModal()"></button>
                </header>
                <section class="modal-card-body">
                    <form @submit.prevent="processSingleTimesheet" v-if="this.processed==false">
                        <div class="columns">
                            <div class="column">
                                <div class="field">
                                    <label class="label">Week</label>
                                    <div class="control">
                                        <select class="select input" v-model="week">
                                            <option :value="row" v-for="row in max_weeks" :key="row">{{ row }}</option>
                                        </select>

                                    </div>
                                </div>
                            </div>
                            <div class="column">
                                <div class="field">
                                    <label class="label">Year</label>
                                    <div class="control">
                                        <select class="select input" v-model="year">
                                                <option :value="row" v-for="row in years" :key="row">{{ row }}</option>
                                            </select>
                                       
                                    </div>
                                </div>
                            </div>
                            
                        </div>
                        <div class="control">
                                <button class="button is-success" type="submit" :disabled="working==true" >Process</button>
                            </div>
                    
                        
                    </form>

                    <div v-if="this.processed==true">
                        <h4 class="title is-size-4">Generated Invoices</h4>

                        <table class="table is-striped is-size-7 is-fullwidth">
                            <thead>
                                <tr>
                                    <th>Day</th>
                                    <th>Duration</th>
                                    <th>Rate</th>
                                    <th>Pay Rate</th>
                                    <th>Charge</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="row in invoices" :key="row.id">
                                    <td>{{ row.day }}</td>
                                    <td>{{ row.duration_mins / 60 }}</td>
                                    <td>{{ row.rate_id }}</td>
                                    <td>{{ row.pay_rate }}</td>
                                    <td>{{ row.charge_rate }}</td>

                                </tr>
                            </tbody>
                        </table>

                        <div class="control">
                            <button class="button" @click="closeProcessModal()">Close</button>
                        </div>
                    </div>
                </section>
                
            </div>
        </div>
           
        
    </div>
</template>

<script>
import BookingService from '@/services/BookingService.js';
import UtilityService from '@/services/UtilityService.js';
import MemberMixin from '@/mixins/MemberMixin.js';
import BookingMixin from '@/mixins/BookingMixin.js';

export default {
    
      name: 'SavedTimesheets',
        mixins: [MemberMixin, BookingMixin],
        data: function () {
            return {
                   search: '',
                   bookings: [],
                   loading: false,
                   showModal: false,
                   showNotesModal: false,
                   alertText: '',
                   modalBooking : [],
                   working:false,
                   booking_notes: '',
                   year: '',
                   week: '',
                   years : [2021, 2022, 2023, 2024],
                   processed : false,
                   invoices: [],
                   
            }
        },
        methods: {
            closeProcessModal() {
                this.showModal=false
                this.processed = false
            },            
            async getApprovedTimesheets() {
                this.loading=true
                UtilityService.getSimpleApiData(this.accessToken, "bookings/completed?timesheet_submitted=1&timesheet_approved=1&timesheet_processed=0")
                .then(
                    (bookings => {
                        this.$set(this, "bookings", bookings);
                        this.loading=false
                    }).bind(this)
                );
            },
            
            async processTimesheets() {
                var formdata = { 
                    booking_ids: this.bookingsToProcess,
                    week: this.week,
                    year: this.year

                }; 

                this.working = true

                BookingService.processTimesheets(this.accessToken, formdata)
                .then(
                    () => {
                        this.working=false;
                        this.getApprovedTimesheets() 
                                    
                    }
                )
                .catch((error) => { 
                    if (error.response.status == 409) {
                        alert(error.response.data.error)
                    } 
                    else{
                        alert(error.message)
                    } 
                    this.working=false;
                })
            },
            async processSingleTimesheet() {
                var formdata = { 
                    week: this.week,
                    year: this.year

                }; 

                this.working = true

                BookingService.processSingleTimesheet(this.accessToken, formdata, this.modalBooking.id)
                .then(
                    (invoices => {
                        this.$set(this, "invoices", invoices);
                        this.working=false
                        this.processed=true
                        this.getApprovedTimesheets() 
                    }).bind(this)
                    
                )
                .catch((error) => { 
                    if (error.response.status == 400) {
                        alert(error.response.data.error)
                    } 
                    else{
                        alert(error.message)
                    } 
                    this.working=false;
                })
            },
           
            viewProcessModal(booking) {
                this.modalBooking = booking
                this.showModal = true
            },
            showNotes(timesheet_notes) {
                this.booking_notes = timesheet_notes

                this.showNotesModal = true
            },
            
        },
        created() {
            this.getAccessToken()
            .then(() => { 
                this.getApprovedTimesheets();
                this.getThisWeekYear();
            });
        
        },
        computed: {
            checkedBookings: function () {
        
                let checkedBookings =  this.filtered_bookings.filter(function(u) { 
                    if(  u.checked == 1) 
                    {
                    return 1
                    }
                })
                return checkedBookings
            },
            bookingsToProcess: function () {
                const bookingsToProcess = this.checkedBookings.map( (booking) => booking.id )

                return bookingsToProcess
            },
            
        }
    }
</script>