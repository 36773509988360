<template>
    <div>
        <div class="notification is-warning" v-if="loading == true">
            Loading bookings ...
        </div>
        <div class="notification is-danger" v-if="loading == false && bookings.length == 0">
            No bookings can be found, which is fine.
        </div>

        <table class="table is-fullwidth is-striped is-hoverable is-size-7" v-if="loading == false && bookings.length > 0">
            <thead>
                <tr>
                    <th width="10">ID</th>
                    <th>Member</th>
                    <th>Client/Ward</th>
                    <th>Email</th>
                    <th>Grade</th>
                    <th>Reference</th>
                    <th>Date</th>
                    <th>Time</th>
                    <th>Year</th>
                    <th>Week</th>
                    
                </tr>
            </thead>
            <tbody>
                <tr v-for="row in bookings" :key="row.id">
                    <td><router-link :to="'/bookings/' + row.id" target="_blank">{{ row.id }}</router-link></td>
                    <td>{{ row.forename }} {{ row.surname  }}</td>
                    <td>{{ row.client_name }} / {{ row.ward_name }}</td>
                    <td><a :href="'mailto:' + row.billing_profile.email + '?subject=Reference required for Booking ' + row.id" target="_blank">{{  row.billing_profile.email  }}</a></td>
                    <td>{{ row.grade_code }}</td>
                    <td>{{ row.reference }}</td>
                    <td><span v-if="row.actual_start_date != null">{{ row.actual_start_date }}</span><span v-else>{{ row.start_date }}</span></td>
                    <td><span v-if="row.actual_start_date != null">{{ row.actual_start_time }} - {{ row.actual_end_time }}</span><span v-else>{{ row.start_time }} - {{ row.end_time }}</span></td>
                    <td>{{ row.year }}</td>
                    <td>{{ row.week }}</td>
                    
                </tr>
               
            </tbody>

        </table>

        <p>Total Count : {{ bookings.length }}</p>

        
    </div>
</template>

<script>
import UtilityService from '@/services/UtilityService.js';
//import BookingService from '@/services/BookingService.js';
import MemberMixin from '@/mixins/MemberMixin.js';

export default {
    
      name: 'MissingReferences',
        mixins: [MemberMixin],
        data: function () {
            return {
                   search: '',
                   bookings: [],
                   loading: true,
                   alertText: '',
                   accessToken: '',
                   working: false
                   
            }
        },
        methods: {
            async getReferenceTimesheets() {
                this.loading=true
                UtilityService.getSimpleApiData(this.accessToken, "bookings/confirmed?start_date=2022-01-01&missing_reference=1&order_by=start_date_time DESC")
                .then(
                    (bookings => {
                        this.$set(this, "bookings", bookings);
                        this.loading=false
                    }).bind(this)
                );
            },
            
            
        },
        created() {
            this.getAccessToken()
            .then(() => { 
                this.getReferenceTimesheets();
                
            });
        
        },
    }
</script>